import { Typography, TypographyProps } from '@mui/material';
import type { MarketType, SpecialValue, SpecialValueModel } from '../../@types';
import { extractSpecialValuesFromOutcomeName, getSpecialValuesDisplayValue } from '../../helpers';

type SpecialValuesDisplayProps = TypographyProps & {
  outcomeName: string;
  specialValues?: SpecialValue[];
  marketTypeName?: string;
  specialValueModel?: SpecialValueModel | null;
  wrapInParenthesis?: boolean;
};

const SpecialValues = ({
  outcomeName,
  specialValues,
  marketTypeName,
  specialValueModel,
  wrapInParenthesis = true,
  ...rest
}: SpecialValuesDisplayProps) => {
  const isHandicapMarket = marketTypeName?.toLowerCase().includes('handicap');

  const specialValuesFromOutcomeName = isHandicapMarket
    ? extractSpecialValuesFromOutcomeName(outcomeName, specialValues, isHandicapMarket, marketTypeName)
    : null;

  const displayValue = getSpecialValuesDisplayValue(specialValuesFromOutcomeName, specialValues);

  const formattedSpecialValues = displayValue && (wrapInParenthesis ? ` (${displayValue})` : ` ${displayValue}`);

  return (
    <Typography component='span' variant='inherit' noWrap {...rest}>
      {specialValueModel?.modelType === 'player' ? ` (${specialValueModel.playerTeamName}) ` : ''}
      {formattedSpecialValues}
    </Typography>
  );
};

type OutcomeProps = TypographyProps & {
  outcomeName: string;
  outcomeShortName?: string;
};

const Outcome = ({ outcomeName, outcomeShortName, ...rest }: OutcomeProps) => {
  return (
    <Typography variant='inherit' component='span' {...rest}>
      {outcomeShortName || outcomeName}
    </Typography>
  );
};

type MarketProps = TypographyProps & {
  marketType: MarketType;
};

const Market = ({ marketType, ...rest }: MarketProps) => (
  <Typography variant='inherit' component='span' {...rest}>
    {marketType.shortName || marketType.name}, {marketType.eventPartName}
  </Typography>
);

export const BetDetails = {
  Outcome,
  SpecialValues,
  Market,
};
