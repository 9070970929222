import { EuroOutlined, LockOutlined, MailOutlined, SettingsOutlined, TodayOutlined } from '@mui/icons-material';
import { BetStatus, BetslipStatus, BettingType, DrawerContentConfig, MenuItemType } from '../@types';

export const SECOND = 1000;

export const QUERY_KEYS = {
  featuredEvents: 'featured-events',
  topOfferTournaments: 'top-offer-tournaments',
  competitions: 'competitions',
  tournaments: 'tournaments',
  upcomingMatches: 'upcoming-matches',
  upcomingMatchesCount: 'upcoming-matches-count',
  sports: 'sports',
  betslip: 'betslip',
  betslips: 'betting-slips',
  publishedEvents: 'published-events',
  events: 'events',
  myBets: 'my-bets',
  myBetsCount: 'my-bets-count',
  copyBetslip: 'copy-betslip',
  betslipEvents: 'betslip-events',
  betHistory: 'bet-history',
  marketTypes: 'market-types',
  favoriteEventsCount: 'favorite-events-count',
  favoriteEvents: 'favorite-events',
  messages: 'messages-client',
  sentMessages: 'messages-client/sent',
  deletedMessages: 'messages-client/deleted',
  userSettings: 'user-settings',
  marketGroups: 'market-groups',
  balance: 'balance',
  topData: 'top-data',
  sideData: 'side-data',
  footerData: 'footer-data',
  agents: 'agents',
  markets: 'markets',
  pendingBetslipsCount: 'pending-betslips-count',
  liveSports: 'live-sports',
  eventPartScores: 'event-part-scores',
  liveEventsCount: 'live-events-count',
  upcomingLiveEvents: 'upcoming-live-events',
  jackpots: 'jackpots',
  outcomes: 'outcomes',
  outrightMarkets: 'outright-markets',
  ticketConditions: 'ticket-conditions',
  cashout: 'cashout',
} as const;

export const BETTING_TYPE_HIT_COUNT_MAP: Record<BettingType, number> = {
  Single: 1,
  Double: 2,
  Treble: 3,
  '4 Fold': 4,
  '5 Fold': 5,
  '6 Fold': 6,
  '7 Fold': 7,
  '8 Fold': 8,
  '9 Fold': 9,
  '10 Fold': 10,
  '11 Fold': 11,
  '12 Fold': 12,
  '13 Fold': 13,
  '14 Fold': 14,
  '15 Fold': 15,
  '16 Fold': 16,
  '17 Fold': 17,
  '18 Fold': 18,
  '19 Fold': 19,
  '20 Fold': 20,
};

export const BETSLIP_WIDTH = 500;

export const ACCOUNT_DRAWER_CONFIG: DrawerContentConfig[] = [
  {
    icon: <EuroOutlined />,
    text: 'Bonus',
    children: [
      {
        text: 'Sportsbook',
        path: '/my-account/bonus/sports',
      },
      {
        text: 'Casino',
        path: '/my-account/bonus/casino',
      },
    ],
  },
  {
    icon: <LockOutlined />,
    text: 'Change Password',
    path: '/my-account/change-password',
    children: [],
  },
  {
    icon: <TodayOutlined />,
    text: 'History',
    children: [
      {
        text: 'Sports Betting',
        path: '/my-account/history/sports-betting',
      },
      {
        text: 'Casino',
        path: '/my-account/history/casino',
      },
    ],
  },
  {
    icon: <MailOutlined />,
    text: 'Messages',
    children: [
      {
        text: 'Inbox',
        path: '/my-account/messages/inbox',
      },
      {
        text: 'Sent',
        path: '/my-account/messages/sent',
      },
      {
        text: 'Deleted',
        path: '/my-account/messages/deleted',
      },
    ],
  },
  {
    icon: <SettingsOutlined />,
    text: 'Preferences',
    path: '/my-account/preferences',
    children: [],
  },
];

export const RESOLVE_STATUS_COLORS = {
  open: '#AEA9B1',
  others: '#DDFCAD',
  won: '#78B159',
  lost: '#FB8C8C',
  void: '#947BE7',
  tie: '#0062CC',
  half_won: ['#0062CC', '#78B159'],
  half_lost: ['#0062CC', '#FB8C8C'],
  cashout: '#F7B61E',
} as Partial<Record<BetStatus, string | string[]>>;

export const RESOLVE_STATUS_TEXT_COLORS = {
  open: 'inherit',
  won: 'success.light',
  lost: 'error.400',
  void: '#947BE7',
  cashout: '#F7B61E',
  others: 'secondary.main',
} as Record<BetslipStatus, string>;

export const BET_HISTORY_ACTION_CELL_MAP = {
  lost: {
    text: 'Lost',
    color: RESOLVE_STATUS_COLORS.lost,
  },
  won: {
    text: 'Won',
    color: RESOLVE_STATUS_COLORS.won,
  },
  void: {
    text: 'Void',
    color: RESOLVE_STATUS_COLORS.void,
  },
  open: {
    text: 'Open',
    color: RESOLVE_STATUS_COLORS.open,
  },
  cashout: {
    text: 'Cashout',
    color: RESOLVE_STATUS_COLORS.cashout,
  },
  others: {
    text: 'Jackpot',
    color: RESOLVE_STATUS_COLORS.others,
  },
} as Record<BetslipStatus, { text: string; color: string }>;

export const BET_HISTORY_TAB_CONFIG = [
  { label: 'All', value: 'all' },
  { label: 'Open', value: 'open' },
  { label: 'Won', value: 'won' },
  { label: 'Lost', value: 'lost' },
  { label: 'Others', value: 'others' },
];

export const SCOREBOARD_TAB_CONFIG = [{ label: 'Scoreboard', value: 'scoreboard' }];

export const drawerWidth = 203;
export const drawerWidthXL = 320;
export const accountDrawerWidth = 268;
export const inPlayDrawerWidth = 320;
export const inPlayDrawerWidthXL = 400;
export const DRAWER_CONFIG = {
  betting: {
    width: {
      xs: drawerWidth,
      xl: drawerWidthXL,
    },
    backgroundColor: 'neutral.800',
  },
  myAccount: {
    width: accountDrawerWidth,
    backgroundColor: 'primary.main',
  },
  inPlay: {
    width: {
      xs: inPlayDrawerWidth,
      xl: inPlayDrawerWidthXL,
    },
    backgroundColor: 'neutral.800',
  },
};

export const HEADER_MENU_ALLOWED_ROUTES = ['my-account', 'sports', 'in-play/match-view'];

export const UPCOMING_MATCHES_TAB_CONFIG = [
  { label: 'Top Leagues', value: 'top-leagues' },
  { label: 'All matches', value: 'all-matches' },
  { label: 'Schedule', value: 'schedule' },
];

export const LANGUAGES: MenuItemType[] = [
  {
    key: 'en',
    label: 'English',
  },
  // {
  //   key: 'sr',
  //   label: 'Srpski',
  // },
  // {
  //   key: 'sq',
  //   label: 'Shqip',
  // },
];

export const ODD_CHANGES: MenuItemType[] = [
  {
    key: 'all',
    label: 'Accept all',
  },
  {
    key: 'none',
    label: 'Accept none',
  },
  {
    key: 'rising',
    label: 'Accept higher',
  },
];

export const ODDS_FORMAT: MenuItemType[] = [
  {
    key: 'decimal',
    label: 'Decimal',
  },
  // {
  //   key: 'fractional',
  //   label: 'Fractional',
  // },
  // {
  //   key: 'american',
  //   label: 'American',
  // },
];
export const ConditionModelTypeEnum = {
  sport: 'sport',
  competition: 'competition',
  tournament: 'tournament',
  marketType: 'market_type',
  event: 'event',
  punter: 'punter',
  ticket: 'ticket',
} as const;

export const PUNTER_COUNTDOWN_DURATION = 60;

export const WS_EVENTS_ENUMS = {
  outcome: 'OUTCOME',
  event: 'EVENT',
  market: 'MARKET',
  eventPartScore: 'EVENT_PART_SCORE',
} as const;

export const WALLET_TYPE_OPTIONS = [
  {
    value: 'main',
    label: 'Main Balance',
  },
  {
    value: 'bonus',
    label: 'Bonus Credits',
  },
];

export const BANKER_MENU_OPTIONS = [
  { value: 'normal', label: 'Normal' },
  { value: 'bankers', label: 'Bankers' },
];

export const CURRENCY = {
  code: 'EUR',
  symbol: '€',
};

export const MARKET_TYPE_ASIAN_HANDICAP_WITH_SCORE = 'Asian Handicap With Score';

export const TOURNAMENT_PAGE_TAB_CONFIG = [
  { label: 'Matches', value: 'matches' },
  { label: 'Outrights', value: 'outrights' },
];

export const EVENT_TYPE_TO_PATH_MAP = {
  match: 'matches',
  outright: 'outrights',
};

export const BETSLIP_TICKET_TYPES = {
  inPlay: 'inPlay',
  preMatch: 'preMatch',
  mix: 'mix',
} as const;
