import { CircularProgress, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'neofusion-fe-shared';
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { StyleObj } from './@types';
import ReactQueryProvider from './ReactQueryProvider';
import router from './Routes';
import StyledSnackbar from './components/atoms/StyledSnackbar';
import { KEYCLOAK_CONFIG, KEYCLOAK_INIT_OPTIONS } from './constants/keycloak';
import { BetslipProvider } from './contexts/BetslipContext';
import { CustomThemeProvider } from './contexts/ThemeContext';
import useBreakpoints from './hooks/useBreakpoints';
import { ModalProvider } from './hooks/useDialog';
import { isIos } from './utils/isIos';
import './App.css';

const styles: StyleObj = {
  circularProgress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
};

const App = () => {
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (isIos()) {
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
      }
    }
  }, []);

  return (
    <AuthProvider keycloakConfig={KEYCLOAK_CONFIG} keycloakInitOptions={KEYCLOAK_INIT_OPTIONS}>
      <CustomThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            Components={{
              success: StyledSnackbar,
              info: StyledSnackbar,
              warning: StyledSnackbar,
            }}
            classes={{ containerAnchorOriginTopCenter: 'snackbar-container' }}
            preventDuplicate
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}            
            dense={isMobile}
            hideIconVariant
            maxSnack={5}
            autoHideDuration={3000}
          >
            <ReactQueryProvider>
              <ReactQueryDevtools initialIsOpen={false} />
              <ModalProvider>
                <BetslipProvider>
                  <CssBaseline />
                  <Suspense fallback={<CircularProgress color='primary' sx={styles.circularProgress} />}>
                    <RouterProvider router={router} />
                  </Suspense>
                </BetslipProvider>
              </ModalProvider>
            </ReactQueryProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </CustomThemeProvider>
    </AuthProvider>
  );
};

export default App;
